interface TeamMember {
  id: string;
  type: string;
  name: string;
  title?: string; // Make this property optional
  desc: string;
  src: string;
  profile: string;
  linkedInProfile: string;
}

const FOUNDERS: TeamMember[] = [
  {
    id: `founder-1`,
    type: `founder`,
    name: `Linta Mustafa`,
    desc: `Co-Founder and Chief Executive Officer`,
    src: `/images/founder-1.png`,
    profile: `Linta is a former national wrestler with an educational background in genetics. She started Vitract after ending her wrestling career due to an RA diagnosis. She is driven to help people like her who are struggling to find help in traditional care. After years of looking for the right functional whole body care, Linta noticed that specialized testing like gut tests were extremely expensive and hard to access. This is what drove her to build Vitract. Vitract exists to not only build gut testing that is superior and more sensitive than other gut tests but to also make gut testing far more accessible than it is today.`,
    linkedInProfile: `https://www.linkedin.com/in/lintamustafa/`,
  },
  {
    id: `founder-2`,
    type: `founder`,
    name: `Chidozie Ojobor, `,
    title: `Ph.D`,
    desc: `Co-Founder and Chief Scientific Officer`,
    src: `/images/founder-2.png`,
    profile: `Chidozie Ojobor is a Molecular Biologist with over 12 years of experience in Medical Microbiology research. In his early research years, he worked on human rotaviruses that cause severe gastroenteritis in children. Afterward, he proceeded to earn his Ph.D. in Molecular Genetics from the University of Toronto, Canada. During his doctoral training, he developed through genetic engineering, novel therapeutic entities that target disease-causing bacteria such as the ones found in complex biological ecosystems as the gut microbiome. These tools he developed worked with high specificity and precision, making them the ideal candidates for targeted therapy. Dr. Ojobor's works in the fields he worked in have been well-cited by numerous scientists across the globe. 

    In early 2022, Dr. Ojobor cofounded Vitract to build comprehensive gut testing that would arm clinical practitioners with the key knowledge that would significantly enhance their practice and improve health outcomes. At Vitract, he is leading the Research and Development team, where they are combining genomics, mathematics, and AI algorithms to demystify the gut microbiome. Beyond Dr. Ojobor's core research expertise, his lived experience with using nonpharmacological dietary recommendations to treat his gut health issues inspired him to pursue aggressive microbiome research at Vitract. Together with his team at Vitract, they are developing the world's most comprehensive gut health solution.`,
    linkedInProfile: `https://www.linkedin.com/in/chidozie-ojobor-ph-d-990484164/`,
  },
];

const TEAM: TeamMember[] = [
  {
    id: `team-1`,
    type: `team`,
    name: `Ken Lassesen, `,
    title: `MSc`,
    desc: `Chief Technology Officer`,
    src: `/images/ot-3.png`,
    profile: `Ken Lassesen is a mathematician and software engineer who first became ill with chronic fatigue syndrome in 1972, then 2001, 2012. Each recovery was connected to a treatment that would have altered gut microbiome (although that was not the intended target for the first two recoveries). 
    
    He believes the symptoms of CFS are caused by gut dysbiosis because it meets Osler criteria -- the simplest model that is consistent with all of the observations. He outlines his model in a paper. Lassesen theorizes that CFS is caused by a shift in gut bacteria. This shift can have many causes including stress, viral infection, bacterial infection, and immunization. The shift in gut bacteria also alters host ability to extract and process nutrients, leading to deficiencies in key amino acids, vitamins, and minerals. 
    
    Variation between patients in the population of gut bacteria explains the heterogeneity of symptoms. Lassesen theorizes that a variety of probiotics may be of benefit in correcting the dysbiosis along with herbal and pharmaceutical antibiotics. The success of treatment and the specific strains most likely to be effective may depend in part on the specific profile of a patient's gut bacteria, which is the aim with our work at Vitract to decode.`,
    linkedInProfile: `https://www.linkedin.com/in/lassesen/`,
  },
  {
    id: `team-2`,
    type: `team`,
    name: `Amy de la Garza, `,
    title: `MD`,
    desc: `Clinical Head of Patient Outcomes`,
    src: `/images/ot-2.jpeg`,
    profile: `Amy is a family physician who has dedicated a large part of her professional practice to the care of patients with substance use disorder. She opened the first community-based primary care and behavioral health practice co-located in Salt Lake City, Utah, United States, where patients who have a primary behavioral health diagnosis, including substance disorder are cared for.
    
    She strongly believes that patients with this diagnosis deserve an integrated approach, including primary care, individual and group therapies, lifestyle education and support including nutrition, exercise, and sleep practices, as well as complementary therapies including acupuncture, physical therapy and mindfulness.
    
    Amy acquired her Bachelor’s Degree in Biology/Biological Sciences and a Doctor of Medicine – MD degree at the University of Colorado and the University of Colorado School of Medicine, respectively. She also got her Family Practice Residency from Utah Healthcare Institute and an Addiction Medicine Fellowship from the University of Utah School of Medicine.`,
    linkedInProfile: `https://www.linkedin.com/in/amy-de-la-garza-221380112/`,
  },
  // {
  //   id: `team-0`,
  //   type: `team`,
  //   name: `Denise Groothuis, `,
  //   title: `MS RD CFMP CLT CPT`,
  //   desc: `Registered Dietitian`,
  //   src: `/images/ot-1.jpg`,
  //   profile: `Denise is a progressive health practitioner and functional dietitian. She believes in an individual approach to health and specializes in gut health and the gut-brain axis. Additionally, she has experience treating weight loss, autism, anxiety, chronic disease, inflammation, diabetes, heart disease, and hormonal issues.

  //   Denise has a BS from Cornell University and an MS in Applied Physiology and Nutrition from Teachers College, Columbia University. She completed her dietetic internship at Columbia University and is a licensed RD (Registered Dietitian). Denise is certified in functional medicine by Functional Medicine University and IFNA (Integrative and Functional Nutrition Academy. She is also LEAP certified.

  //   Additionally, Denise is a personal trainer and works with children and adults of all abilities.`,
  //   linkedInProfile: `https://www.linkedin.com/in/denise-groothuis-8172444b/`,
  // },
];

export { FOUNDERS, TEAM };
