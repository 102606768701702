import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import Link from '@/components/Link';
import { FOUNDERS, TEAM } from '@/data/care-team';

export default function AboutUs() {
  const [isMobile, setIsMobile] = useState(false);
  const [displayFoundersProfile, setDisplayFoundersProfile] = useState(
    FOUNDERS.map(() => false),
  );

  const [displayTeamProfile, setDisplayTeamProfile] = useState(
    TEAM.map(() => false),
  );

  const toggleFoundersProfile = (index) => {
    const foundersProfileState = [...displayFoundersProfile];
    foundersProfileState[index] = !foundersProfileState[index];
    setDisplayFoundersProfile(foundersProfileState);
  };

  const toggleTeamProfile = (index) => {
    const teamProfileState = [...displayTeamProfile];
    teamProfileState[index] = !teamProfileState[index];
    setDisplayTeamProfile(teamProfileState);
  };

  const profileLocation = useLocation();

  useEffect(() => {
    const anchorLink = profileLocation.hash;

    if (anchorLink) {
      const teamId = anchorLink.slice(1); // Remove the '#' character

      // Find the team member with the matching ID
      const careTeam = [...FOUNDERS, ...TEAM].find(
        (teamMember) => teamMember.id === teamId,
      );

      if (careTeam) {
        if (careTeam.type === `founder`) {
          setDisplayFoundersProfile((prevState) => {
            const updatedState = [...prevState];
            updatedState[FOUNDERS.indexOf(careTeam)] = true;
            return updatedState;
          });
        } else if (careTeam.type === `team`) {
          setDisplayTeamProfile((prevState) => {
            const updatedState = [...prevState];
            updatedState[TEAM.indexOf(careTeam)] = true;
            return updatedState;
          });
        }
      }
    }
  }, [profileLocation]);

  useEffect(() => {
    // Check if window is defined (browser environment)
    if (typeof window !== `undefined`) {
      const windowWidth = window.innerWidth;
      const mobileThreshold = 768;

      setIsMobile(windowWidth < mobileThreshold);
    }
  }, []); // Empty dependency array to run the effect once

  // Adding Line Breaks
  const addLineBreaks = (text: string) =>
    text.split(`\n`).map((line, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index}>
        {line}
        <br />
      </div>
    ));

  return (
    <section className="relative w-full overflow-hidden bg-white">
      <div>
        <h2 className="w-full max-w-[1181px] mx-5 md:mx-8 xlg:mx-auto font-bold text-4xl md:text-6xl xlg:text-[96px] xlg:leading-[129.6px] text-[#484352] py-8 md:py-6 slg:py-10">
          About Us
        </h2>
        <div>
          <img
            src="/images/about-us-header2.svg"
            alt=""
            className="object-cover w-full"
          />
        </div>

        <div className="flex flex-col w-auto">
          {isMobile ? (
            // Mobile View
            <div className="w-full flex flex-col justify-center items-center gap-y-[40px] py-8 px-5">
              <p className="text-[24px] leading-normal font-normal">
                Vitract is a digital health practice that treats mental illness
                by focusing on its root cause rather than symptoms, through a
                holistic treatment plan including in-depth laboratory testing,
                personalized dietary recommendations and lifestyle changes.
              </p>

              <img
                src="/images/about-us-body.svg"
                alt=""
                className="w-[251.5px]"
              />

              <Link
                href="/pricing"
                className="w-[107px] h-auto flex justify-center text-[11px] md:text-[16px] text-[#FFFFFF] font-medium bg-primary py-[10px] rounded-[4px] hover:shadow-2xl hover:scale-[1.015] hover:bg-navy-blue transition-all duration-[0.1s] ease-in-out"
              >
                Shop
              </Link>
            </div>
          ) : (
            // Desktop View
            <div className="flex flex-row justify-between items-center w-[705px] mlg:w-[762px] lg:w-[848px] slg:w-[920px] xl:w-[1150px] mx-8 slg:mx-auto h-[410px] xl:h-[619px] my-6 xl:my-[120px]">
              <div className="w-[285px] mlg:w-[298px] slg:[360px] xl:w-[450px]">
                <img
                  src="/images/about-us-body.svg"
                  alt=""
                  className="object-contain w-full h-full"
                />
              </div>
              <div className="flex flex-col gap-y-[40px] w-[400.07px] mlg:w-[424.07px] lg:w-[499px] slg:w-[560px] xl:w-[640px] h-auto">
                <p className="text-[24px] xl:text-[32px] leading-normal font-normal">
                  Vitract is using cutting edge technology that combines
                  molecular biology and artificial intelligence, to unravel and
                  solve digestive and brain health disorders that are connected
                  to the gut.
                </p>

                <Link
                  href="/pricing"
                  className="w-[107px] h-auto flex justify-center text-[11px] md:text-[16px] text-[#FFFFFF] font-medium bg-primary py-[10px] rounded-[4px] hover:shadow-2xl hover:scale-[1.015] hover:bg-navy-blue transition-all duration-[0.1s] ease-in-out"
                >
                  Shop
                </Link>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col justify-center w-full min-h-[1027px] bg-navy-blue relative py-[40px]">
          <h2 className="text-[#F4F1BB] text-[32px] xl:text-[40px] font-bold leading-normal text-center">
            Care Team
          </h2>

          <div className="w-full max-w-[978px] mx-auto pt-10">
            {FOUNDERS.map((founder, index) => (
              <div
                key={founder.id}
                className={`w-[260px] xs:w-[335px] md:w-[561px] slg:w-[780px] xl:w-[978px] h-full mx-auto bg-[${
                  displayFoundersProfile[index] ? `#9DC7FF` : `#FFFFFF`
                }] hover:bg-[#9DC7FF] rounded-2xl flex flex-col my-[10px] p-[12px] gap-x-4`}
              >
                <div
                  onClick={() => toggleFoundersProfile(index)}
                  onKeyDown={() => toggleFoundersProfile(index)}
                  role="button"
                  tabIndex={0}
                  className="flex flex-col xs:flex-row gap-x-4"
                >
                  <div className="w-[92.351px] h-auto lg:w-[161px]">
                    <img
                      src={founder.src}
                      alt={founder.name}
                      className="w-full h-full rounded-lg"
                    />
                  </div>

                  <div className="flex flex-col items-start justify-center">
                    <h3 className="flex flex-wrap lg:flex-nowrap font-bold text-[#19233F] text-sm lg:text-xl">
                      {founder.name}

                      {founder.title && (
                        <span className="pl-1 text-sm font-bold lg:text-xl">
                          {founder.title}
                        </span>
                      )}
                    </h3>
                    <h6 className="font-medium italic text-[#484352] text-xs lg:text-base">
                      {founder.desc}
                    </h6>
                  </div>
                </div>
                {displayFoundersProfile[index] && (
                  <div className="flex flex-col my-4 text-lg">
                    {addLineBreaks(founder.profile)}
                    <Link
                      href={founder.linkedInProfile}
                      target="_blank"
                      className="text-[#122279] text-sm font-bold underline pt-4"
                    >
                      VIEW MORE
                    </Link>
                  </div>
                )}
              </div>
            ))}

            {TEAM.map((team, index) => (
              <div
                key={team.id}
                className={`w-[260px] xs:w-[335px] md:w-[561px] slg:w-[780px] xl:w-[978px] h-full mx-auto bg-[${
                  displayTeamProfile[index] ? `#9DC7FF` : `#FFFFFF`
                }] hover:bg-[#9DC7FF] rounded-2xl flex flex-col my-[10px] p-[12px] gap-x-4`}
              >
                <div
                  onClick={() => toggleTeamProfile(index)}
                  onKeyDown={() => toggleTeamProfile(index)}
                  role="button"
                  tabIndex={0}
                  className="flex flex-col xs:flex-row gap-x-4"
                >
                  <div className="w-[92.351px] h-auto lg:w-[161px]">
                    <img
                      src={team.src}
                      alt={team.name}
                      className="w-full h-full rounded-lg"
                    />
                  </div>

                  <div className="flex flex-col items-start justify-center">
                    <h3 className="flex font-bold flex-wrap lg:flex-nowrap text-[#19233F] text-sm lg:text-xl">
                      {team.name}

                      {team.title && (
                        <span className="pl-1 text-sm font-bold lg:text-xl">
                          {team.title}
                        </span>
                      )}
                    </h3>
                    <h6 className="font-medium italic text-[#484352] text-xs lg:text-base">
                      {team.desc}
                    </h6>
                  </div>
                </div>
                {displayTeamProfile[index] && (
                  <div className="flex flex-col my-4 text-lg">
                    {addLineBreaks(team.profile)}
                    <Link
                      href={team.linkedInProfile}
                      target="_blank"
                      className="text-[#122279] text-sm font-bold underline pt-4"
                    >
                      VIEW MORE
                    </Link>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
